<template>
  <div class="container has-background-white-ter">


    <h2>New</h2>



    <router-view />
    <section v-if="loaded && $route.path == '/school/assessments'" class="section">

      <h3>Hellllo</h3>
      <div v-for="(r, i) in formRows" :key="i" class="tile is-ancestor">

        <div v-for="index in 3" :key="index" class="tile is-parent">
          <article class="tile is-child box" :style="'visibility: '+ ((r[index-1]) ? 'visible:': 'hidden')">
   <!----        <p class="title">Third column</p>
            <p class="subtitle">With some content</p> -->
            <div v-if="r[index-1]" class="content">
              <div class="is-flex-direction-row" style="justify-content: space-evenly; display: flex; padding-right:2px">
              <span class="subtitle margin-right:auto"><router-link target="_blank" :to="{ path: '/form/' + r[index-1].reference }">{{ r[index-1].reference }} </router-link></span>
                <span v-if="r[index-1].isPublished" v-tooltip="'Published'"> <Icon icon="gridicons:visible"   class="subtitle "  /></span>
                <span v-else  v-tooltip="'Hidden'"><Icon icon="gridicons:not-visible"  class="subtitle "  /></span>
              </div>
              <p>{{ r[index-1].title}}</p>


            <div class="is-flex-direction-row" style="justify-content: space-evenly; display: flex;">

              <span class="margin-right:auto">{{ niceDate(r[index-1].date)}}</span>
              <router-link :to="{ path: '/forms/id/' + r[index-1].reference }">more details</router-link>
            </div>
          </div>
          </article>
        </div>
      </div>




    </section>
  </div>






</template>

<script>
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';
import { Icon } from "@iconify/vue2";
//import lunch from "helpers/lunch";
import { format } from 'date-fns' ;
//import { sum } from 'pdf-lib';
//import { toast } from 'bulma-toast'

//import LunchOption from "./LunchOption.vue";


export default {
  name: "forms",

  components: {
    Icon,
  },

  data: function () {
    return {
      loaded: false,
      assessments: []
    };
  },


  computed: {

    formID() {
      return this.$route.params?.formID ?? ''
    },

    formRows() {
      if (!this.loaded) {
        return []
      }

      console.log(["asasasasasasaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa  "])
      let x = (this.assessments ?? []).reduce((p, c) => {

        let x = c.name.split("_#_")

        p.current.push({
          date: x[2],
          formID: x[3],
          ...c.metadata
        })

        if (p.current.length == 3) {
          p.rtn.push(p.current)
          p.current = []
        }

        return p

      },
        {
          current: [],
          rtn: []
        }
      )

      if (x.current.length) {
        x.rtn.push(x.current)
      }

      return x.rtn


    }
  },

  methods: {

    niceDate: (x) => {

      return format(new Date(x), "do MMM YYY")



    }
  },















  async mounted() {


    console.log(this.$route)


    this.loaded = false;
    this.$api
      .get("v2/staff/assessments/", true, true)
      .then((response) => {
        this.assessments = response

      })
      .finally(() => {
        console.log("Finished..............")
        this.loaded = true;
      });

    //this.loadRegister();
  },
};
</script>



<style>
a.router-link-exact-active {
  color: inherit;
}


div.box>div.field {
  margin-top: auto;
  margin-bottom: auto;
}
</style>